import BaseLayout from './BaseLayout';
import Breadcrumb from '@/components/Breadcrumb';
import Container from '@/components/Container';
import {
  HeadingSection,
  IdContainer,
  NullSection,
} from '@/components/Css/Layout';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { Tit } from '@/components/Titles';
import ROUTES, {
  getRoutePath,
  ServerRoute,
  serverToRoute,
} from '@/config/routes';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

const NullSectionContainer = styled(NullSection)`
  ${breakpoint(`mobile`)} {
    display: none;
  }
`;

const LayoutWithTitle: FC<{
  location?: PageProps['location'];
  title: string;
  description?: string;
  hideGnb?: boolean;
  paddingBtt?: boolean;
  borderBtt?: boolean;
}> = ({ children, location, title, description, hideGnb, paddingBtt }) => {
  const {
    allMenu: { nodes: serverMenus },
  } = useStaticQuery<{
    allMenu: { nodes: ServerRoute[] };
  }>(graphql`
    query {
      allMenu {
        nodes {
          id
          level
          menuCode
          menuName
          menuUrl
          offspring {
            level
            menuCode
            menuParentCode
            menuColumnOrder
            menuName
            menuUrl
            offspring {
              level
              menuCode
              menuName
              menuParentCode
              menuUrl
            }
          }
        }
      }
    }
  `);
  const menus = [...serverMenus.map(serverToRoute), ...ROUTES];
  const uri = location?.pathname.replace(/\/$/, ``);
  const routePath = uri ? getRoutePath(uri, menus) : [];
  return (
    <BaseLayout>
      <Header routePath={routePath} hideGnb={hideGnb} />
      <NullSectionContainer hideGnb={hideGnb} />
      <HeadingSection backgroundColor="#1cabe2">
        <Container>
          <div className="heading-container">
            <Breadcrumb routePath={routePath} />
            <h1>
              <Tit size="s1" color="white">
                {title}
              </Tit>
            </h1>
            <Tit size="s3-1" color="white" className="header-dec">
              {description}
            </Tit>
          </div>
        </Container>
      </HeadingSection>
      <IdContainer className={paddingBtt && `nonePadding`}>
        {children}
      </IdContainer>
      <Footer />
    </BaseLayout>
  );
};

export default LayoutWithTitle;
