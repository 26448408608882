import styled from 'styled-components';

const ErrorMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.8px;
  color: #e2231a;
  ::after {
    background-size: 100%;
    background-position: 0 -16px;
  }
`;

export default ErrorMessage;
