import { breakpoint } from '@/helpers/BreakpointHelper';
import styled, { css } from 'styled-components';

const Required = css`
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    background: #1cabe2;
    border-radius: 100%;
    position: absolute;
    right: -8px;
    top: 50%;
    margin-top: -2px;
  }
`;

const Label = styled.label<{ required?: boolean; bold?: boolean }>`
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.8px;
  display: inline-block;
  ${({ required }) => required && Required}
  ${({ bold }) => bold && `font-weight: bold;`}
  ${breakpoint(`mobile`)} {
    font-size: 14px;
  }
`;

export default Label;
