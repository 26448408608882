import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const NotiBox = styled.div`
  background: #f8f9fa;
  padding: 16px 24px;
  margin-bottom: 16px;
  * {
    letter-spacing: -0.8px;
    font-size: 14px;
    line-height: 24px;
  }
`;
export const NotiTitle = styled.p`
  font-weight: normal;
  margin-bottom: 4px;
`;
export const NotiParagraph = styled.p`
  font-size: 14px;
  font-weight: 300;
`;
export const NotiList = styled.ul``;

export const NotiItem = styled.li`
  position: relative;
  font-weight: 300;
  padding-left: 14px;
  ${breakpoint(640)} {
    font-size: 14px;
    padding-left: 8px;
  }
  & + li {
    margin-top: 6px;
  }
  &::after {
    content: '';
    display: block;
    width: 3px;
    height: 3px;
    background: #1cabe2;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 10px;
  }
`;
