import PopupX from '@/assets/img/icons/icon_layer_popup_x@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC } from 'react';
import styled from 'styled-components';
import Modal, { ModalProps } from './Modal';
import { H3 } from './Titles';

const PopupContainer = styled.div`
  padding: 17px 32px;
  ${breakpoint(`mobile`)} {
    padding: 10px 24px;
  }
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #1cabe2;
  ${breakpoint(`mobile`)} {
    padding-bottom: 4px;
  }
`;

const PopupTitle = styled(H3)`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -1.2px;
  color: #1cabe2;
  ${breakpoint(`mobile`)} {
    font-size: 18px;
    letter-spacing: -0.8px;
  }
`;

const PopupXButton = styled.button`
  width: 40px;
  height: 40px;
  background-image: url(${PopupX});
  background-size: 100%;
`;

const PopupBody = styled.div`
  padding: 32px 0;
`;

interface LayerPopupProps extends ModalProps {
  title: string;
}

const LayerPopup: FC<LayerPopupProps> = ({
  title,
  children,
  onRequestClose,
  ...props
}) => (
  <Modal onRequestClose={onRequestClose} {...props}>
    <PopupContainer>
      <PopupHeader>
        <PopupTitle>{title}</PopupTitle>
        <PopupXButton onClick={() => onRequestClose()} />
      </PopupHeader>
      <PopupBody>{children}</PopupBody>
    </PopupContainer>
  </Modal>
);

export default LayerPopup;
