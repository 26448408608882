import Button from '@/components/Button';
import Col from '@/components/Grid/Col';
import { H2 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 584px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  ${breakpoint(`tablet`)} {
    max-width: 100%;
  }
`;
export const PageTitle = styled.div`
  margin: 0 0 94px 0;
  ${H2} {
    font-weight: normal;
    font-size: 40px;
    color: #1cabe2;
    line-height: 1.5;
    letter-spacing: -2px;
  }
  p {
    margin-top: 22px;
  }
  ${breakpoint(640)} {
    margin: 48px 0 48px 0;
    ${H2} {
      font-size: 24px;
    }
  }
`;
export const BorderLinkTab = styled.div`
  width: 544px;
  margin: 0 auto;
  display: flex;
  line-height: 80px;
  ${breakpoint(640)} {
    width: 100%;
    margin: 0 auto;
    line-height: 56px;
  }
`;
export const BorderLinkButton = styled.div<{ active?: boolean }>`
  width: 100%;
  text-align: center;
  border-bottom: 3px solid #e5e6e8;
  color: #b7b8ba;
  font-size: 20px;
  font-weight: bold;
  ${({ active }) =>
    active &&
    `
    border-bottom: 3px solid #1cabe2;
    color: #1cabe2;
    `}
  ${breakpoint(640)} {
    font-size: 16px;
  }
`;
export const InputButton = styled(Button)`
  width: 160px;
  height: 56px;
  font-size: 16px;
  line-height: 2;
`;
export const ToggleForm = styled.div<{ show?: boolean }>`
  display: none;
  ${({ show }) => {
    if (show) {
      return `
        display: block;
      `;
    }
    return ``;
  }}
`;
export const ButtonGroup = styled.div`
  button {
    width: 48%;
  }
`;
export const SocialButton = styled.div`
  cursor: pointer;
  display: inline-block;
`;
export const SocialButtonText = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.8px;
  ${breakpoint(`mobile`)} {
    font-size: 14px;
  }
`;

// input 옆 버튼
export const LeftCol = styled(Col)`
  padding-right: 32px;
  ${breakpoint(`mobile`)} {
    padding-right: 16px;
  }
`;

export const RightCol = styled(Col)`
  padding-left: 32px;
  ${breakpoint(`mobile`)} {
    padding-left: 16px;
  }
`;

export const FormButton = styled(Button).attrs({ type: `button` })`
  width: 160px;
  font-size: 16px;
  padding: 15px 20px;
  ${({ outline }) => outline && `background-color: #fff;`}
  ${breakpoint(`mobile`)} {
    width: 80px;
    font-size: 14px;
    padding: 17px 0px;
  }
`;
