import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

const Col = styled.div<{
  desktop?: number | string;
  mobile?: number | string;
  align?: 'left' | 'center' | 'right';
}>`
  flex: ${({ desktop }) => desktop || 1};
  ${({ mobile }) =>
    mobile &&
    `
  ${breakpoint(`mobile`)} {
    flex: ${mobile}
  }
  `};
  ${({ align }) => align && `text-align: ${align};`};
`;

export default Col;
