import Row from '../Grid/Row';
import { H4 } from '../Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

const FormGroup = styled.div<{ noMargin?: boolean; border?: boolean }>`
  margin-bottom: 24px;
  ${({ noMargin }) => noMargin && `margin-bottom: 0;`}
  ${({ border }) =>
    border && `border-bottom: 1px solid #e5e6e8; padding-bottom: 40px;`}
  ${Row} {
    margin-bottom: 8px;
  }
`;
export const FormItem = styled.div<{ bgGray?: boolean; noBorder?: boolean }>`
  padding-bottom: 24px;
  &:last-child {
    padding-bottom: 0;
  }
  border-bottom: 1px solid #e5e6e8;
  img {
    max-width: 100%;
  }
  ${({ noBorder }) => noBorder && `border-bottom: none;`}
  ${H4} {
    padding-bottom: 16px;
    font-size: 20px;
    ${breakpoint(640)} {
      font-size: 16px;
    }
  }
  ${({ bgGray }) =>
    bgGray &&
    `
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 1920px;
      height: 100%;
      position: absolute;
      background: #f8f9fa;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      z-index: -1;
    }
  `}
`;
export default FormGroup;
