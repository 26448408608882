import iconEyeOutline from '@/assets/img/icons/icon_eye_outline@2x.png';
import React, { forwardRef, InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import InputText from './InputText';

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  ${InputText} {
    padding: 16px;
  }
`;

type InputPasswordProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

const IconImg = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
  ({ ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
      <InputContainer>
        <InputText
          type={showPassword ? `text` : `password`}
          ref={ref}
          {...props}
        />
        <IconImg
          src={iconEyeOutline}
          alt="비밀번호보기"
          width="24"
          height="24"
          onClick={() => setShowPassword(!showPassword)}
        />
      </InputContainer>
    );
  },
);

export default InputPassword;
