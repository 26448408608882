import { Route } from '@/config/routes';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

interface BreadcrumbProps {
  routePath: Route[];
}

export const BreadcrumbList = styled.ul`
  text-align: right;
  font-size: 14px;
  ${breakpoint(640)} {
    display: none;
  }
`;

export const BreadcrumbItem = styled.li`
  display: inline-block;
  color: #828385;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.8px;
  & + &::before {
    content: '>';
    padding: 0 5px;
  }
  &:last-child a {
    color: #2d2926;
    font-weight: bold;
  }
`;

const Breadcrumb: FC<BreadcrumbProps> = ({ routePath }) => (
  <BreadcrumbList className="BreadcrumbList">
    <BreadcrumbItem key={0}>
      <Link to="/">Home</Link>
    </BreadcrumbItem>
    {routePath.map((route) => (
      <BreadcrumbItem key={route.id}>
        <Link to={route.link}>{route.name}</Link>
      </BreadcrumbItem>
    ))}
  </BreadcrumbList>
);

export default Breadcrumb;
